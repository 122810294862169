import axios from "@/api/config/interceptor";
import { host, friendHost } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 获取所有运单列表
export const getAllBillList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/WayBillQuery/QueryWayBill`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
// 获取小程序录单列表
export const getMpBillList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/WayBillQuery/QueryWayBillEx`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 导出所有运单的excel
export const allBillDwon = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/WayBillQuery/DownLoadWayBills`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 导出所有小程序运单的excel
export const mpAllBillDwon = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/WayBillQuery/DownLoadWayBillExs`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 修改小程序运单
export const updatewayBill = (params) => {
  return new Promise((resolve, reject) => {
    axios.put(`${host}/api/Applet/Owner/WayBill`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
// 获取小程序运单上传的图片
export const getMpImgInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/WayBillQuery/GetWayBillExEvidence?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 配置其他运单的账号 // 好伙伴
export const setFriendAccount = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${friendHost}/api/BsetFriend`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
//  获取其他运单的全部运单 //拉取数据按钮  好伙伴
export const getFriendAllWayBill = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${friendHost}/api/BsetFriend/waybill/all?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//  筛选其他运单的全部运单 //用于筛选  好伙伴
export const filterFriendList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${friendHost}/api/BsetFriend/waybill/chche`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 五流合一查询
export const queryWayBillDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/WayBillQuery/QueryWayBillDetail?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 五流合一发票流查询
export const GetWaybillInvoice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/WayBillQuery/GetWaybillInvoice`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取打印订单数据
export const getPrintWaybill = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/WayBillQuery/WaybillDetailPrint?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询运单 -- 单条运单
export const queryWaybillOnlyOne = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/WayBillQuery/QueryWaybillOnlyOne`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 工运宝运单
// 场景运单--------------------------------------------------------
export const getConstructionBillList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/WayBillController/List`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
// 查看运单详情
export const getConstructionBillDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/WayBillController/Detial`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
// 查询结算单明细
export const getSettleDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Settle/SettleDetail`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
// 工运宝运单发往运单库
export const sendOrderStore = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${host}/api/WayBillController/Create?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
//快捷录单获取凭证配置
export const evidenceConfig = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/Applet/Owner/EvidenceConfig`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
//快捷录单运单详情
export const getOrderDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Applet/Owner/WayDetail?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
//快捷录单修改运单
export const updateWayBill = (params) => {
  return new Promise((resolve, reject) => {
    axios.put(`${host}/api/Applet/Owner/WayBill`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
//快捷录单获取司机信息
export const getDriverInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Applet/Owner/WayBillDriver`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
//快捷录单获取车辆信息
export const getVehicleInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/Applet/Owner/WayBillCar?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//核销运单
export const waybillCheck = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Applet/Owner/WaybillCheck`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
