<template>
  <div class="mpWaybillList">
    <!-- <el-breadcrumb separator="/" style="margin: 10px 0px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>待开运单</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 表格区域 -->

    <div style="max-height:84.5vh">
      <!-- <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="小程序运单" name="1"></el-tab-pane>
        <el-tab-pane label="其他运单" name="2"></el-tab-pane>
      </el-tabs> -->
      <div>
        <div class="topbtns">
          <!-- <el-button icon="el-icon-download" type="primary" size="medium" @click="exportExcel">导出表格</el-button> -->
        </div>

        <el-form class="searchForm" :model="searchForm" ref="searchForm">
          <div style="display: flex; flex-wrap: wrap;">
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="企业名称：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.companyName"
                  placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="司机手机号：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.driverPhone"
                  placeholder="请输入司机手机号">
                </el-input>
              </el-form-item>
            </div>
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item label="收款人手机号：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.receivePhone"
                  placeholder="请输入收款人手机号">
                </el-input>
              </el-form-item>
              <el-form-item label="车牌号：" prop="keyword" label-width="120px">
                <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.carNumber"
                  placeholder="请输入车牌号">
                </el-input>
              </el-form-item>

              <el-form-item label-width="10px">
                <div style="white-space:nowrap;">
                  <el-button type="primary" :disabled="loading" size="medium" @click="search('searchForm')"
                    icon="el-icon-search">搜索</el-button>
                  <el-button type="primary" :disabled="loading" size="medium" icon="el-icon-delete"
                    @click="resetForm('searchForm')">清空</el-button>
                  <el-button type="primary" size="medium" :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                    @click="isShowSelect">更多选项</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <el-collapse-transition>
            <div style="display: flex; flex-wrap: wrap;" v-show="showSelect">
              <div style="display: flex; flex-wrap: nowrap;">
                <el-form-item label="起始地：" label-width="120px">
                  <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.startAddress"
                    placeholder="请输入起始地">
                  </el-input>
                </el-form-item>
                <el-form-item label="到达地：" label-width="120px">
                  <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.arriveAddress"
                    placeholder="请输入到达地">
                  </el-input>
                </el-form-item>
                <el-form-item label="运单号：" label-width="120px">
                  <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.wayBillID"
                    placeholder="请输入运单号">
                  </el-input>
                </el-form-item>
                <el-form-item label="签收时间：" prop="keyword" label-width="120px">
                  <el-date-picker @change="search" v-model="searchForm.time" type="datetimerange"
                    start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div style="display: flex; flex-wrap: nowrap;">
                <el-form-item label="核销验证码：" prop="cancelCode" label-width="120px">
                  <el-input @keyup.enter.native="search" style="width: 180px;" v-model="searchForm.cancelCode"
                    placeholder="请输入核销验证码">
                  </el-input>
                </el-form-item>
                <el-form-item label="是否已核销：" prop="isCancel" label-width="120px">
                  <el-select v-model="searchForm.isCancel" @change="isCancelChange" placeholder="请选择是否已核销："
                    style="width: 208px">
                    <el-option v-for="item in cancelEnum" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-collapse-transition>
        </el-form>
        <el-table :data="tableData" v-loading="loading" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column fixed align="center" prop="sindex" label="序号" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200"></el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200"></el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200"></el-table-column> -->
          <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.DriverPhone }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CheckCode" label="核销验证码" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.WaybillStatus == 1 ? scope.row.CheckCode : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="CarNumber" label="车牌" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.CarNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="LoadNetWeight" label="装货净重(吨)" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsUnitName" label="货品单位" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.OriginAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.ArriveAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverAllotCount" label="司机装货数量" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverCarryPrice" label="司机运输单价（人民币）" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.DriverCarryPrice | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.CarriageTotalPrice | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.ExpensesOfTaxation | formatMoney }}
              </div>

            </template>
          </el-table-column>
          <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#FF8B17;">
                {{ scope.row.TotalAmount | formatMoney }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="UnloadNetWeight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="220">
            <template slot-scope="scope">
              <el-button type="primary" size="small" icon="el-icon-edit-outline" @click="editOrder(scope.row)">编辑
              </el-button>
              <el-button v-if="scope.row.WaybillStatus == 0" type="primary" size="small"
                @click="openNoCancelDialog(scope.row)">未核销</el-button>
              <el-button v-if="scope.row.WaybillStatus == 1" type="success" size="small"
                @click="openAlreadyCancelDialog(scope.row)">已核销</el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="TaskStatus" label="运单状态" width="200">
           <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.TaskStatus == 3 ? 'success' : 'danger'"> {{scope.row.TaskStatus == 3  ? '已开票' : '未开票'}}</el-tag>
            </div>
          </template>
        </el-table-column> -->
        </el-table>

        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page"
          :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 编辑运单dialog -->
    <el-dialog class="vehicleType" :visible.sync="showEditDialog" :loading="flag.loadingWaybillDetail" width="1200px"
      style="margin-top: 1vh;">
      <span slot="title" class="dialog-title">
        编辑运单
      </span>
      <div class="body">
        <div style="height: 60vh;overflow-y:auto">
          <el-form class="addForm" :model="addForm" ref="addForm" :rules="addRule" label-width="157px">
            <el-form-item label="货主单位" prop="goodsOwnerName">
              <el-input v-model="addForm.goodsOwnerName" placeholder="请输入货主单位" disabled></el-input>
            </el-form-item>
            <el-form-item label="货品名称" prop="goodsName">
              <el-input v-model="addForm.goodsName" placeholder="请输入货品名称"></el-input>
            </el-form-item>
            <el-form-item label="货品单位" prop="goodsUnit">
              <el-select v-model="addForm.goodsUnit" clearable placeholder="请选择货品单位" style="width:292px">
                <el-option v-for="item in goodsUnitList" :key="item.Name" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="司机姓名" prop="driverName">
              <el-select v-model="addForm.driverName" filterable remote reserve-keyword placeholder="请输入司机姓名"
                :remote-method="getDriverInfo" @change="chooseDriverInfo" :loading="flag.loadingDriverList">
                <el-option v-for="item in driverList" :key="item.UserID" :label="item.DriverName" :value="item.UserID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="司机身份证号码" prop="driverIDCard">
              <el-input v-model="addForm.driverIDCard" placeholder="请输入司机身份证号码" disabled></el-input>
            </el-form-item>
            <el-form-item label="司机手机号码" prop="driverPhone">
              <el-input v-model="addForm.driverPhone" placeholder="请输入司机手机号码" disabled></el-input>
            </el-form-item>
            <el-form-item label="司机装货数量" prop="driverAllotCount">
              <el-input v-model="addForm.driverAllotCount" placeholder="请输入司机装货数量"></el-input>
            </el-form-item>
            <el-form-item label="运费" prop="carriageTotalPrice">
              <el-input v-model="addForm.carriageTotalPrice" placeholder='数字精确到小数点后两位;"数量x单价=运费"'></el-input>
            </el-form-item>
            <el-form-item label="起始地" prop="originAddress">
              <el-input v-model="addForm.originAddress" placeholder="必须填写省、市、区道路精确到地址"></el-input>
            </el-form-item>
            <el-form-item label="装货时间" prop="loadingDatetime">
              <el-date-picker v-model="addForm.loadingDatetime" type="datetime" format="yyyy-MM-dd HH:mm"
                placeholder="请选择装货时间" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="到达地" prop="arriveAddress">
              <el-input v-model="addForm.arriveAddress" placeholder="必须填写省、市、区道路精确到地址"></el-input>
            </el-form-item>
            <el-form-item label="签收时间" prop="signDatetime">
              <el-date-picker v-model="addForm.signDatetime" type="datetime" format="yyyy-MM-dd HH:mm"
                placeholder="请选择签收时间" value-format="yyyy-MM-dd HH:mm">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="收款人身份证号码" prop="payeeIDCard">
              <el-input v-model="addForm.payeeIDCard" placeholder="请输入收款人身份证号码"></el-input>
            </el-form-item>
            <el-form-item label="收款人姓名" prop="payeeName">
              <el-input v-model="addForm.payeeName" placeholder="请输入收款人姓名"></el-input>
            </el-form-item>
            <el-form-item label="收款人手机号码" prop="payeePhone">
              <el-input v-model="addForm.payeePhone" placeholder="请输入收款人手机号码"></el-input>
            </el-form-item>
            <el-form-item label="收款人银行卡号" prop="payeeBankCard">
              <el-input v-model="addForm.payeeBankCard" placeholder="请填写收款人银行卡号"></el-input>
            </el-form-item>
            <el-form-item label="车牌" prop="carNumber">
              <el-select v-model="addForm.carNumber" filterable remote reserve-keyword placeholder="请输入车牌号"
                :remote-method="getVehicleInfo" :loading="flag.loadingVehicleList">
                <el-option v-for="item in vehicleList" :key="item.VehicleCarNumber" :label="item.VehicleCarNumber"
                  :value="item.VehicleCarNumber">
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="车牌" prop="carNumber">
              <el-input v-model="addForm.carNumber" placeholder="请输入车牌"></el-input>
            </el-form-item> -->
            <el-form-item label="装货净重(吨)" prop="loadNetWeight">
              <el-input v-model="addForm.loadNetWeight" placeholder="请输入装货净重"></el-input>
            </el-form-item>
            <el-form-item label="卸货净重(吨)" prop="unloadNetWeight">
              <el-input v-model="addForm.unloadNetWeight" placeholder="数字精确到小数点后两位"></el-input>
            </el-form-item>
          </el-form>
          <input type="file" accept="image/*" style="display:none" @change="handleUploadVoucher" multiple ref="voucher">
          <div v-for="(item, index) in this.currentItem.voucherList" :key="index">
            <el-divider content-position="left">{{ item.name }}</el-divider>
            <el-button type="primary" :loading="flag.uploadvouchering"
              @click="handleUploadVoucherClick(item.evidenceTypeId)">添加新凭证</el-button>
            <div class="voucherWrap" v-if="item.hasOwnProperty('imgList')">
              <div class="item" v-for="(el, inx) in item.imgList" :key="el">
                <i class="delete el-icon-delete" @click="deleteVoucher(item, inx)"></i>
                <el-image style="width: 200px; height: 100px; margin-bottom: 10px;" :src="el" :preview-src-list="[el]">
                  <div slot="error" class="image-slot"
                    style="display: flex; justify-content: center; margin-top: 30px;">
                    <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                  </div>
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button size="medium" @click="showEditDialog = false">取消</el-button>
        <el-button type="primary" size="medium" @click="editRow('addForm')">确认</el-button>
      </span>
    </el-dialog>

    <!-- 未核销弹窗 -->
    <el-dialog class="vehicleType" title="核销运单" :visible.sync="flag.noCancelDialog" width="1200px" center>
      <span>核销验证码</span>
      <el-input v-model="cancelForm.cancelCode" size="mini" style="width: 140px; margin-left: 16px;"
        placeholder="请输入核销验证码"></el-input>
      <!-- 凭证上传区 -->
      <div v-for="(item, index) in this.currentItem.voucherList" :key="index">
        <el-divider content-position="left">{{ item.name }}</el-divider>
        <el-button type="primary" :loading="flag.uploadvouchering"
          @click="handleUploadVoucherClick(item.evidenceTypeId)">添加新凭证</el-button>
        <div class="voucherWrap" v-if="item.hasOwnProperty('imgList')">
          <div class="item" v-for="(el, inx) in item.imgList" :key="el">
            <i class="delete el-icon-delete" @click="deleteVoucher(item, inx)"></i>
            <el-image style="width: 200px; height: 100px; margin-bottom: 10px;" :src="el" :preview-src-list="[el]">
              <div slot="error" class="image-slot" style="display: flex; justify-content: center; margin-top: 30px;">
                <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
              </div>
            </el-image>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.noCancelDialog = false" size="mini">取 消</el-button>
        <el-button :loading="flag.cancelWaybill" type="primary" size="mini" @click="waybillCheck">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 已核销弹窗 -->
    <el-dialog title="已核销信息" class="vehicleType" :visible.sync="flag.alreadyCancelDialog" width="1200px" center>
      <div v-for="(item, index) in currentItem.voucherList" :key="index">
        <el-divider content-position="left">{{ item.name }}</el-divider>
        <div class="voucherWrap" v-if="item.hasOwnProperty('imgList')">
          <div class="item" v-for="(el, i) in item.imgList" :key="i">
            <el-image style="width: 200px; height: 100px; margin-bottom: 10px;" :src="el" :preview-src-list="[el]">
              <div slot="error" class="image-slot" style="display: flex; justify-content: center; margin-top: 30px;">
                <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
              </div>
            </el-image>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="flag.alreadyCancelDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMpBillList, mpAllBillDwon, updatewayBill, getMpImgInfo, evidenceConfig, getOrderDetail, updateWayBill, getDriverInfo, getVehicleInfo, waybillCheck } from '@/api/waybill/otherAndAllBill/index'
import { getDataDict, uploadImg } from '@/api/common/common'
import { host } from '@/api/config/host'
export default {
  data() {
    // 正则校验规则
    var validator1 = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的司机手机号码'));
      } else {
        callback();
      }
    };

    var validator2 = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的收货人手机号码'));
      } else {
        callback();
      }
    };
    var validator4 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入正确的装货数量'));
      } else {
        callback();
      }
    };
    var validator5 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入正确的运输单价'));
      } else {
        callback();
      }
    };
    var validator6 = (rule, value, callback) => {
      if (!/^([1-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入正确的运费'));
      } else {
        callback();
      }
    };
    // 非必填验证
    var validator14 = (rule, value, callback) => {
      if (value) {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的卸货人手机号码'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      host: host + '/api/Image/Upload/Img', // 上传图片的地址
      flag: {
        loadingWaybillDetail: false, //获取运单详情中
        uploadvouchering: false, //上传凭证中
        loadingDriverList: false, //搜索司机列表中
        loadingVehicleList: false, //搜索车辆列表中
        noCancelDialog: false, //未核销弹窗
        cancelWaybill: false, //核销运单中
        alreadyCancelDialog: false, //已核销弹窗
      },
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      addForm: {
        waybillID: '',
        driverName: '',
        driverPhone: '',
        driverIDCard: '',
        carNumber: '',
        goodsOwnerName: '',
        goodsName: '',
        driverAllotCount: '',
        goodsUnit: '',
        carriageTotalPrice: '',
        originAddress: '',
        loadingDatetime: '',
        arriveAddress: '',
        signDatetime: '',
        payeeName: '',
        payeePhone: '',
        payeeIDCard: '',
        unloadNetWeight: '',
        loadNetWeight: '',
        driverUserID: '',
        payeeBankCard: ''
      },
      addRule: {
        goodsOwnerName: [{ required: true, message: '请输入货主单位', trigger: 'blur' }],
        driverName: [{ required: true, message: '请输入司机姓名', trigger: 'blur' }],
        driverPhone: [{ required: true, validator: validator1, trigger: 'blur' }],
        payeeName: [{ required: true, message: '请输入收款人姓名', trigger: 'blur' }],
        payeePhone: [{ required: true, validator: validator2, trigger: 'blur' }],
        carNumber: [{ required: true, message: '请输入车牌', trigger: 'blur' }],
        goodsName: [{ required: true, message: '请输入货品名称', trigger: 'blur' }],
        goodsUnit: [{ required: true, message: '请选择货品单位', trigger: 'change' }],
        loadingDatetime: [{ required: true, message: '请选择装货时间', trigger: 'change' }],
        signDatetime: [{ required: true, message: '请选择签收时间', trigger: 'change' }],
        originAddress: [{ required: true, message: '请填写起始地', trigger: 'blur' }],
        arriveAddress: [{ required: true, message: '请填写到达地', trigger: 'blur' }],
        driverAllotCount: [{ required: true, validator: validator4, trigger: 'blur' }],
        driverCarryPrice: [{ required: true, validator: validator5, trigger: 'blur' }],
        carriageTotalPrice: [{ required: true, validator: validator6, trigger: 'blur' }],
        unloadGoodsContactsPhone: [{ validator: validator14, trigger: 'blur' }],
        payeeIDCard: [{ required: true, message: '请填写收款人身份证', trigger: 'blur' }],
        driverIDCard: [{ required: true, message: '请填写司机身份证', trigger: 'blur' }],
      },
      voucherList: [], //凭证列表
      // 搜索表单
      searchForm: {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        wayBillID: '', // 运单号
        time: '',// 时间
        //  type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '', // 到达地
        isCancel: '', //是否已核销
        cancelCode: '', //核销验证码
      },
      cancelEnum: [
        { value: '', label: '全部' },
        { value: 0, label: '未核销' },
        { value: 1, label: '已核销' },
      ],
      tableData: [], //表格数据
      sceneList: [], //场景类型
      statusList: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: '1',
          label: '未完成流程'
        },
        {
          value: '2',
          label: '已完成流程'
        }
      ], // 进度状态
      // 是否禁用提交按钮
      // showSubmit: false,
      loading: false,
      showEditDialog: false, // 显示编辑弹框
      goodsUnitList: [], // 货品单位数据字典
      showSelect: false, // 展开隐藏搜索框
      currentItem: {}, //当前操作的对象
      currentEvidenceTypeId: '', //当前上传的凭证类型id
      driverList: [], //搜索出来的司机列表
      vehicleList: [], //搜索出来的车辆列表
      cancelForm: {
        cancelCode: '', //核销验证码
      }
    }
  },

  methods: {
    //是否已核销变化
    isCancelChange(e) {
      this.searchForm.isCancel = e
      this.search()
    },
    //提交核销
    waybillCheck() {
      if (!this.cancelForm.cancelCode) {
        this.$message.error('请填写核销验证码')
        return
      }
      this.flag.cancelWaybill = true
      let params = {
        wayBillId: this.currentItem.WaybillID,
        checkCode: this.cancelForm.cancelCode,
        evidences: [],
      }
      this.currentItem.voucherList.forEach(item => {
        let obj = {
          evidenceTypeID: item.evidenceTypeId,
          imgUrls: []
        }
        item.imgList.forEach(el => {
          obj.imgUrls.push(el)
        })
        params.evidences.push(obj)
      })
      waybillCheck(params).then(res => {
        this.getAllBillList()
        this.flag.noCancelDialog = false
        this.$message.success('修改成功')
      }).finally(() => {
        this.flag.cancelWaybill = false
      })
    },
    //打开已核销弹窗
    openNoCancelDialog(item) {
      this.currentItem = item
      this.flag.noCancelDialog = true
      this.currentItem.voucherList = JSON.parse(JSON.stringify(this.voucherList))
      getOrderDetail({ waybillId: item.WaybillID }).then(res => {
        this.currentItem.voucherList.forEach(item => {
          res.data.Evidences.forEach(el => {
            if (item.evidenceTypeId === el.EvidenceTypeID) {
              item.imgList = el.ImgUrls
            }
          })
        })
        this.$forceUpdate()
      })
    },
    //打开未核销弹窗
    openAlreadyCancelDialog(item) {
      this.currentItem = item
      this.flag.alreadyCancelDialog = true
      this.currentItem.voucherList = JSON.parse(JSON.stringify(this.voucherList))
      getOrderDetail({ waybillId: item.WaybillID }).then(res => {
        this.currentItem.voucherList.forEach(item => {
          res.data.Evidences.forEach(el => {
            if (item.evidenceTypeId === el.EvidenceTypeID) {
              item.imgList = el.ImgUrls
            }
          })
        })
        this.$forceUpdate()
      })
    },
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect
    },
    //搜索
    search(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //搜索前初始化page
      this.pagination.page = 1
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        //   taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time ? this.searchForm.time[0] : '',
        importTimeEnd: this.searchForm.time ? this.searchForm.time[1] : '',
        waybillId: this.searchForm.wayBillID,
        checkCode: this.searchForm.cancelCode,
        wayBillStatus: this.searchForm.isCancel
      }
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
      //   }
      //   })
    },
    // 编辑提交
    editRow(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return
        let params = {
          waybill: {},
          evidences: [],
        }
        // 方法与小程序一致
        params.waybill = {
          waybillID: this.addForm.waybillID,
          driverName: this.addForm.driverName,
          driverPhone: this.addForm.driverPhone,
          driverIDCard: this.addForm.driverIDCard,
          carNumber: this.addForm.carNumber,
          goodsOwnerName: this.addForm.goodsOwnerName,
          goodsName: this.addForm.goodsName,
          driverAllotCount: this.addForm.driverAllotCount,
          goodsUnit: this.addForm.goodsUnit,
          carriageTotalPrice: this.addForm.carriageTotalPrice,
          originAddress: this.addForm.originAddress,
          loadingDatetime: this.addForm.loadingDatetime,
          arriveAddress: this.addForm.arriveAddress,
          signDatetime: this.addForm.signDatetime,
          payeeName: this.addForm.payeeName,
          payeePhone: this.addForm.payeePhone,
          payeeIDCard: this.addForm.payeeIDCard,
          payeeBankCard: this.addForm.payeeBankCard,
          unloadNetWeight: this.addForm.unloadNetWeight ? this.addForm.unloadNetWeight : 0,
          driverCarryPrice: (this.addForm.carriageTotalPrice / this.addForm.driverAllotCount).toFixed(2),
          loadNetWeight: this.addForm.loadNetWeight ? this.addForm.loadNetWeight : 0,
          driverUserID: this.addForm.driverUserID
        }
        this.currentItem.voucherList.forEach(item => {
          let obj = {
            evidenceTypeID: item.evidenceTypeId,
            imgUrls: []
          }
          item.imgList.forEach(el => {
            obj.imgUrls.push(el)
          })
          params.evidences.push(obj)
        })
        updateWayBill(params).then(res => {
          this.getAllBillList()
          this.showEditDialog = false
          this.$message.success('修改成功')
        })
      })
    },
    //清空
    resetForm(formName) {
      this.loading = true
      this.searchForm = {
        companyName: '', // 企业名称
        driverPhone: '', // 司机手机号
        receivePhone: '', // 收款人手机号
        carNumber: '', // 车牌
        // wayBillID: '', // 运单号
        time: [],// 时间
        // type: '01', // 场景类型 默认为查找承运业务
        //  status: '0', // 进度状态
        startAddress: '', // 起始地
        arriveAddress: '', // 到达地
      }
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        wayBillStatus: this.searchForm.isCancel
      }
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //导出excel
    exportExcel() {
      let params = {
        //  taskType: this.searchForm.type,
        //   wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1]
      }
      mpAllBillDwon(params).then(res => {
        window.location.href = res.data
      })
    },
    // 编辑运单弹框
    editOrder(row) {
      this.showEditDialog = true
      this.flag.loadingWaybillDetail = true
      this.currentItem = row
      this.currentItem.voucherList = JSON.parse(JSON.stringify(this.voucherList))
      getOrderDetail({ waybillId: row.WaybillID }).then(res => {
        this.addForm.payeeBankCard = res.data.PayeeBankCard
        this.currentItem.voucherList.forEach(item => {
          res.data.Evidences.forEach(el => {
            if (item.evidenceTypeId === el.EvidenceTypeID) {
              item.imgList = el.ImgUrls
            }
          })
        })
        this.flag.loadingWaybillDetail = false
      })
      // 回显运单数据
      this.addForm = {
        waybillID: row.WaybillID, // 修改的运单ID
        // 必填字段
        driverName: row.DriverName,
        driverPhone: row.DriverPhone,
        carNumber: row.CarNumber,
        originAddress: row.OriginAddress,
        loadingDatetime: row.LoadingDatetime,
        arriveAddress: row.ArriveAddress,
        signDatetime: row.SignDatetime,
        loadNetWeight: row.LoadNetWeight,
        goodsOwnerName: row.GoodsOwnerName,
        goodsName: row.GoodsName,
        goodsUnit: row.GoodsUnit,
        payeeName: row.PayeeName,
        payeePhone: row.PayeePhone,
        driverAllotCount: row.DriverAllotCount,
        driverCarryPrice: row.DriverCarryPrice,
        carriageTotalPrice: row.CarriageTotalPrice,
        unloadNetWeight: row.UnloadNetWeight,
        driverIDCard: row.DriverIDCard,
        payeeIDCard: row.PayeeIDCard,
      }
      // 获取用户上传的图片 
      let params = {
        waybillId: row.WaybillID
      }
    },
    //tbale选中改变
    tableSelectionChange(e) {
    },
    // 切换场景类型
    changeType(val) {
      this.searchForm.type = val
      this.search()
    },
    // 切换进度状态
    changeProgress(val) {
      this.searchForm.status = val
      this.search()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        wayBillStatus: this.searchForm.isCancel
      }
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //获取所有运单列表
    getAllBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        //  taskType: this.searchForm.type,
        //  wayBillStatus: this.searchForm.status,
        driverPhone: this.searchForm.driverPhone,
        carNumber: this.searchForm.carNumber,
        goodsOwnerName: this.searchForm.companyName,
        originAddress: this.searchForm.startAddress,
        arriveAddress: this.searchForm.arriveAddress,
        payeePhone: this.searchForm.receivePhone,
        taskSceneTypeID: 0,
        importTimeStart: this.searchForm.time[0],
        importTimeEnd: this.searchForm.time[1],
        wayBillStatus: this.searchForm.isCancel
      }
      this.loading = true;
      getMpBillList(params).then(res => {
        this.tableData = res.data.DataList
        let i = 1;
        this.tableData.forEach((element) => {
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    //获取场景类型列表
    getSceneList() {
      getDataDict({ type: 27 }).then(res => {
        this.sceneList = res.patterSetInfo
      })
    },
    //获取货品单位列表
    getGoodsUnitList() {
      getDataDict({ type: 28 }).then(res => {
        this.goodsUnitList = res.patterSetInfo
      })
    },
    //获取凭证配置
    evidenceConfig() {
      evidenceConfig().then(res => {
        res.data.forEach(item => {
          this.voucherList.push({
            name: item.Name,
            evidenceTypeId: item.EvidenceTypeId,
            imgList: []
          })
        })
      })
    },
    //设置上传的哪个凭证分类
    handleUploadVoucherClick(e) {
      this.currentEvidenceTypeId = e
      this.$refs.voucher.click()
    },
    //上传凭证
    handleUploadVoucher(e) {
      if (e.srcElement.files.length > 10) {
        this.$message.warning('最多可同时上传10个文件')
        this.$refs.voucher.value = null
        return
      }
      let count = 0
      let fileLength = e.srcElement.files.length
      for (let i = 0; i < fileLength; i++) {
        let formData = new FormData()
        formData.append('file', e.srcElement.files[i])
        uploadImg(formData).then(res => {
          this.currentItem.voucherList.forEach(item => {
            if (item.evidenceTypeId === this.currentEvidenceTypeId) {
              item.imgList.push(res.data)
              this.$forceUpdate()
            }
          })
        }).finally(() => {
          this.$refs.voucher.value = null
          count += 1
          if (count === fileLength) {
            this.flag.uploadvouchering = false
          }
        })
      }
    },
    //删除一个凭证
    deleteVoucher(item, inx) {
      this.$confirm('是否确定删除该凭证', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        item.imgList.splice(inx, 1)
        this.$forceUpdate()
      })
    },
    //搜索司机信息
    getDriverInfo(e) {
      if (!e) return
      this.flag.loadingDriverList = true
      getDriverInfo({ driverName: e }).then(res => {
        this.driverList = res.data
      }).finally(() => {
        this.flag.loadingDriverList = false
      })
    },
    //选择司机信息
    chooseDriverInfo(e) {
      this.driverList.forEach(item => {
        if (item.DriverName === e) {
          this.addForm.driverUserID = item.UserID
          this.addForm.driverIDCard = item.DriverIDCard
          this.addForm.driverName = item.DriverName
          this.addForm.driverPhone = item.TelPhone
        }
      })
    },
    //搜索车辆信息
    getVehicleInfo(e) {
      if (!e) return
      this.flag.loadingVehicleList = true
      getVehicleInfo({ carNumber: e }).then(res => {
        this.vehicleList = res.data
      }).finally(() => {
        this.flag.loadingVehicleList = false
      })
    },
  },
  created() {
    //获取凭证配置
    this.evidenceConfig()
    //获取全部运单列表
    this.getAllBillList()
    //获取场景类型列表
    this.getSceneList()
    //获取货品单位列表
    this.getGoodsUnitList()
  },

}
</script>

<style>
.vehicleType .el-dialog {
  top: -10%;
}

.v-modal {
  z-index: 2000 !important;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>

<style scoped lang="scss">
@import '../../../../../assets/style/variable.scss';

.mpWaybillList {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
      padding-top: 28px !important;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>